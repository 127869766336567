import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialBannerStore = {
  needOpen: true,
};

export const BannerStore = t
  .model({
    needOpen: t.boolean,
  })
  .actions((self) => ({
    clearState: () => {
      applySnapshot(self, initialBannerStore);
    },
    toOpen: () => {
      self.needOpen = false
    },
  }));
