import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {
    ENUM_REFUNDREQUEST_TEMPLATE,
    ENUM_REFUNDREQUEST_TYPE,
    RefundRequestInput
} from '../../../__generated__/global-types';
import {Banner} from '../../../components/Banner/Banner';
import FormLayout from '../../../components/FormLayout/FormLayout';
import {
    ERROR_CERT_NUMBER,
    SUCCESS_NON_RETURN, SUCCESS_ON_PERSONAL, SUCCESS_PURCHASE_PLACE, SUCCESS_SEVEN_DAYS, SUCCESS_WALK_TO_MFO,
    SUCCESS_WALK_TO_OFFICE,
    toSuccessDefaultRequest,
    toSuccessWalkToMail
} from '../../../config/routes';
import {IFormRefundFree} from '../../../shared/forms/forms';
import {
    createRefundRequest,
    createRefundRequestVariables
} from '../../../shared/graphql/__generated__/createRefundRequest';
import {CREATE_REFUND_REQUEST} from '../../../shared/graphql/createRefundRequest';
import {Contacts} from '../../index/Contacts/Contacts';

import image from './doc.svg';
import RefundFreeForm from './RefundFreeForm';


const RefundFree: FC = () => {

    const navigate = useNavigate();
    const [createRefundRequest, {loading}] = useMutation<createRefundRequest,createRefundRequestVariables>(CREATE_REFUND_REQUEST)

    const onComplete = async (values:IFormRefundFree) => {

        const data:RefundRequestInput = {
            FIO: values.FIO,
            Email: values.Email,
            Phone: values.Phone,
            requestData: values,
            type: ENUM_REFUNDREQUEST_TYPE.free,
        }

        await createRefundRequest({
            variables: {
                data: data,
            },
            onCompleted: (data) => {
                const template = data.createRefundRequest?.data?.attributes?.template!
                const url = data.createRefundRequest?.data?.attributes?.requestPdfFileUrl!
                switch (template) {
                    case ENUM_REFUNDREQUEST_TEMPLATE.SEVEN_DAYS: navigate(SUCCESS_SEVEN_DAYS); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_MFO: navigate(SUCCESS_WALK_TO_MFO); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.PURCHASE_PLACE: navigate(SUCCESS_PURCHASE_PLACE); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.DEFAULT_REQUEST: navigate(toSuccessDefaultRequest(url)); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.ON_PERSONAL: navigate(SUCCESS_ON_PERSONAL); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.CERT_NUMBER_ERROR: navigate(ERROR_CERT_NUMBER); break;

                    case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_OFFICE: navigate(SUCCESS_WALK_TO_OFFICE); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.WALK_TO_MAIL: navigate(toSuccessWalkToMail(url)); break;
                    case ENUM_REFUNDREQUEST_TEMPLATE.NON_RETURN: navigate(SUCCESS_NON_RETURN); break;
                }
            }
        })
    }

    return (
    <div>
        <div className={'content'}>
            {/*<Banners/>*/}
        </div>

        <FormLayout image={image}>
            <h2>Заявление на возврат</h2>
            <RefundFreeForm onComplete={onComplete} loading={loading}/>
        </FormLayout>

        <div className={'content'}>
            <Contacts/>
        </div>

        <Banner/>

    </div>
)};

export default RefundFree;



